<template>
  <div class="cabinet">
    <left-side-bar
      :is-open="isMenuOpened"
      @closeMenu="beforeClose"
      :user-name="userName"
      :user-position="userPosition"
      :user-is-checked="userIsChecked"
      :new-docs="newDocs"
      :new-info="newInfo"
      :new-p-i-f="newPIF"
      :is-redemption="isRedemption"
      :is-changes-visible="isChangesVisible"
    />
    <div class="cabinet-wrapper">
      <app-bar
        :is-cabinet="true"
        :contacts="contacts"
        :messages-count="messagesCount"
        @sendMail="$emit('sendMail')"
        @openMenu="beforeOpen"
        @logout="$emit('logout')"
        @showMessages="$emit('showMessages')"
        @sendMessage="$emit('sendMessage')"
      />
      <div class="cabinet-wrapper-content">
        <slot />
      </div>
      <footer-bar />
    </div>
  </div>
</template>

<script>
import AppBar from "./AppBar";
import FooterBar from "./FooterBar";
import LeftSideBar from "./LeftSideBar";

export default {
  name: "CabinetWrapper",
  components: { LeftSideBar, FooterBar, AppBar },
  props: {
    isChangesVisible: {
      type: Boolean
    },
    isRedemption: {
      type: Boolean,
      default: false,
    },
    userIsChecked: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: "User",
    },
    userPosition: {
      type: String,
      default: "Инвестор",
    },
    messagesCount: {
      type: Number,
      default: 0,
    },
    newDocs: {
      type: Boolean,
      default: false,
    },
    newPIF: {
      type: Boolean,
      default: false,
    },
    newInfo: {
      type: Boolean,
      default: false,
    },
    contacts: Object,
  },
  data() {
    return {
      isMenuOpened: false,
    };
  },
  methods: {
    beforeOpen() {
      this.isMenuOpened = true;
      let body = document.getElementsByClassName("cabinet-wrapper-content")[0];
      body.classList.add("modal-open");
    },
    beforeClose() {
      let body = document.getElementsByClassName("cabinet-wrapper-content")[0];
      body.classList.remove("modal-open");
      this.isMenuOpened = false;
    },
  },
};
</script>

<style scoped lang="scss">
.cabinet {
  display: flex;
  height: 100vh;
  overflow: hidden;
  &-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-x: hidden;
    @media screen and (min-width: 961px) {
      padding-left: 44px;
    }
    &-content {
      flex: 1 1 auto;
      transition: all 300ms;
      padding: 44px 42px;
      /*min-height: calc(100vh - 400px);*/
      @media screen and (min-width: 961px) {
        padding: 44px 42px 44px 0;
      }
      & div {
        min-height: 1015px;
      }
    }
  }
}
</style>
